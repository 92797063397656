@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

body {
  font-family: 'Montserrat', sans-serif;
  position: relative;
}

$acc-blue: rgb(33, 18, 97);
$acc-yellow: #b9a019;

.border-blue {
  border-color: $acc-blue !important;
}
a {
  color: #333;

  &:active,
  &:hover {
    color: #ababab;
  }
}
.nav-item.nav-link {
  transition: 150ms ease-in-out all;
  &.active {
    border-bottom: 1px solid $acc-yellow;
    transition: 150ms ease-in-out all;
  }
}

.bg-blue-translucent-overlay {
  background-color: transparent;

  transition-duration: 200ms;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: rgba(33, 18, 97, 0.5);
  }
}

.nav-pills {
  .nav-link.active {
    background-color: $acc-yellow;
  }
}

.card-socio {
  object-fit: cover;
  object-position: top;
  width: 250px !important;
  height: 250px !important;
}
.carousel-caption {
  color: #000;
  text-align: justify;
  background: rgba(240, 240, 240, 0.9);
  padding-left: 20px;
  padding-right: 20px;
}

.bg-parallax {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  min-height: 100vh;
  min-width: auto;
}

.bg-black {
  background: #000;
}

.img-blur {
  filter: blur(2px);
}

.border-yellow {
  border-color: $acc-yellow !important;
}

.display-5 {
  word-wrap: break-word;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  word-wrap: break-word;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.bg-blue {
  background-color: $acc-blue;
}

.bg-yellow {
  background-color: $acc-yellow;
}

.text-blue {
  color: $acc-blue;
}
.text-yellow {
  color: $acc-yellow;
}
.text-black {
  color: black;
}

.nav-translucent {
  opacity: 1;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
}

.bg-white-translucent {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-yellow-translucent {
  background-color: rgba(185, 160, 25, 0.5);
}

.bg-blue-translucent {
  background-color: rgb(33, 18, 97);
}

.btn-outline-yellow {
  color: #ffffff;
  background-color: $acc-yellow;

  &:hover {
    background-color: transparent;
    color: $acc-yellow;
    border-color: $acc-yellow;
  }
}

.bg-gray {
  background-color: #d8dee3;
}
.bg-light-gray {
  background-color: #dedede;
}

.lang-pt {
  color: gray;
  font-size: 10pt;

  &::after {
    font-family: "Apple Color Emoji", "Noto Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    content: "🇧🇷";
  }
}

.lang-en {
  color: gray;
  font-size: 10pt;

  &::after {
    font-family: "Apple Color Emoji", "Noto Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    content: "🇺🇸";
  }
}

.top-right {
  top: 2vh;
  right: 2vw;
}

.list-horizontal {
  text-align: center;
  vertical-align: baseline;
  margin-top: 0;
  margin-bottom: 0;

  li {
    display: inline-block;
    margin: 10px;
  }
}

.bg-ice {
  background-color: #e9ecef !important;
}

.bg-ice-light {
  background-color: #f8f9fa;
}

.u-blue {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: $acc-blue;
}

.bg-black-translucent {
  background-color: rgba(0,0,0, 0.8);
}

.wide-img {
  height: auto;
  width: 95%;
}
